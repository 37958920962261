var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "2 0 14 17" } },
    [
      _c("title", [_vm._v("Line Insertion")]),
      _c("g", { attrs: { id: "Capa_2", "data-name": "Capa 2" } }, [
        _c("g", { attrs: { id: "Capa_1-2", "data-name": "Capa 1" } }, [
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M15.64,3.63l-1.25.15V2.23a.32.32,0,0,0-.32-.32.32.32,\n        0,0,0-.32.32V3.89l-.61.61V3.38a.32.32,0,0,0-.64,0V5.1s0,\n        0,0,0l-.39.39A6,6,0,0,0,8.07,4a6.11,6.11,0,1,0,4.5,\n        2l.15-.15,2-.19A.32.32,0,0,0,15,5.28.32.32,0,0,0,14.64,\n        5l-1.21.12.66-.65,1.63-.19a.32.32,0,1,0-.08-.64ZM13.5,\n        10a5.46,5.46,0,1,1-1.83-4l-1,1A4,4,0,0,0,8.07,6a4,4,0,\n        1,0,4,4,4,4,0,0,0-1-2.59l1-1A5.37,5.37,0,0,1,13.5,10ZM9,\n        8.6a1.72,1.72,0,1,0,.45.45l1.17-1.17A3.34,3.34,0,0,1,\n        11.45,10a3.43,3.43,0,1,1-1.24-2.59Z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }